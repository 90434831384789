<template>
  <section class="content">
    <table class="table table-hover" ref="tblproposal">
      <thead>
        <tr>
          <th>HARI</th>
          <th>TANGGAL</th>
          <th>NAMA KEGIATAN</th>
          <th>DOKUMEN KEGIATAN</th>
          <th>STATUS</th>
          <th>STATUS PJ</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p v-if="errors.length" class="alert alert-danger">
                  <b>Please correct the following error(s):</b>
                  <ul>
                      <li v-for="error in errors">{{ error }}</li>
                  </ul>
              </p>
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">PROPOSAL TANGGAL MULAI</label>
                  <datepicker
                    placeholder="Tanggal Mulai"
                    v-model="form.proposal_tanggal_mulai"
                    :config="tanggalAwal"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">PROPOSAL TANGGAL SELESAI</label>
                  <datepicker
                    placeholder="Tanggal Selesai"
                    v-model="form.proposal_tanggal_selesai"
                    :config="tanggalSelesai"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">NAMA KEGIATAN</label>
                  <input
                    id="nama_kegiatan"
                    class="form-control"
                    v-model="form.nama_kegiatan"
                    type="text"
                    name="nama_kegiatan"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">DESKRIPSI KEGIATAN</label>
                  <textarea
                    class="form-control"
                    id="deskripsi_kegiatan"
                    rows="3"
                    v-model="form.deskripsi_kegiatan"
                    placeholder="Tuliskan deskripsi kegiatan"
                  ></textarea>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">STAFF</label>
                  <v-select
                    label="name"
                    name="name"
                    :filterable="false"
                    :options="staffPic"
                    v-model="form.staff_pic"
                    :reduce="(opt) => opt.id"
                    @search="onSearch"
                    placeholder="Ketik nama/code/no. KTP"
                  >
                    <template slot="no-options"> Tidak ada... </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nama_staff }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nama_staff }}
                      </div>
                    </template>
                  </v-select>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">PROPOSAL ANGGARAN</label>
                  <!-- <input
                    id="proposal_anggaran"
                    class="form-control"
                    v-model="form.proposal_anggaran"
                    type="number"
                    name="proposal_anggaran"
                  /> -->
                  <currency-input id="proposal_anggaran" class="form-control" currency="IDR" v-model="form.proposal_anggaran" :precision="0" name="proposal_anggaran" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">LPJ ANGGARAN</label>
                  <!-- <input
                    id="pj_anggaran"
                    class="form-control"
                    v-model="form.pj_anggaran"
                    type="number"
                    name="pj_anggaran"
                  /> -->
                  <currency-input id="pj_anggaran" class="form-control" currency="IDR" v-model="form.pj_anggaran" :precision="0" name="pj_anggaran" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">LPJ LAPORAN</label>
                  <textarea
                    class="form-control"
                    id="pj_laporan"
                    name="pj_laporan"
                    rows="3"
                    v-model="form.pj_laporan"
                    placeholder="Tuliskan Laporan LPJ"
                  ></textarea>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">CABANG</label>
                  <select
                    v-model="form.branch_id"
                    id="branch_id"
                    name="branch_id"
                    class="form-control"
                  >
                    <option
                      v-for="cabang in groups"
                      v-bind:key="cabang.branch_id"
                      v-bind:value="cabang.branch_id"
                    >
                      {{ cabang.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import datepicker from "@/components/Datepicker";
import moment from "moment";
import { CurrencyInput } from "vue-currency-input";

export default {
  name: "",
  data() {
    return {
      staffPic: [],
      tanggalAwal: {
        minDate: moment().add(2, "h").toDate(),
        format: "DD/MM/YYYY HH:mm",
      },
      tanggalSelesai: {
        minDate: moment().add(1, "d").toDate(),
        format: "DD/MM/YYYY HH:mm",
      },
      errors: [],
      groups: [],
      method: "",
      roles: "",
      formTitle: "Tambah Ruangan",
      form: {
        proposal_tanggal_mulai: moment().add(2, "h").toDate(),
        proposal_tanggal_selesai: moment().add(1, "d").toDate(),
        branch_id: [],
        nama_kegiatan: "",
        deskripsi_kegiatan: "",
        staff_pic: "",
        proposal_anggaran: "",
        pj_anggaran: "",
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    this.loadStaff();
    //get cabang
    authFetch("/akademik/data_induk_ruangan/groups")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.groups = js.data;
      });
  },
  methods: {
    handleClick(e) {
      //   if (e.target.matches(".link-role")) {
      //     this.$router.push({ path: "/permit/" + e.target.dataset.id });
      //     return false;
      //   }

      if (e.target.matches(".btn-info")) {
        if (e.target.dataset.action == "view") {
          this.$router.push("/detail-proposal-lpj/" + e.target.dataset.id);
        }
        console.log(e.target);
        return false;
      }
    },
    loadStaff() {
      authFetch("/pr/proposal_lpj_kegiatan/staff").then((res) => {
        res.json().then((json) => {
          //get default address
          if (json.success) {
            this.staffPic = json.data
            // json.data.map(function(val, key) {
            //   this.staffPic.push({
            //     id: val.staff_pic,
            //     nama_staff: val.nama_staff,
            //   });
            // })
          }
        });
      });
    },
    onSearch(search, loading) {
      if (search.length > 3) {
        loading(true);
        var vm = this;
        authFetch(
          `/pr/proposal_lpj_kegiatan/cari_staff?q=${escape(search)}`
        ).then((res) => {
          res.json().then((json) => (vm.staffPic = json.items));
          loading(false);
        });
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;
      Swal.fire({
        title: "Mohon tunggu...",
        text: "Sedang menyimpan data",
        imageUrl: "images/ajaxloader.gif",
        showConfirmButton: false,
        allowOutsideClick: false,
        buttons: false,
      });
      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/pr/proposal_lpj_kegiatan";
      if (this.method == "PUT")
        urlSubmit = "/pr/proposal_lpj_kegiatan/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          Swal.fire({
              icon: 'success',
              title: 'Informasi',
              text: 'Data telah tersimpan.'
          })
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblproposal, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/pr/proposal_lpj_kegiatan",
      columns: [
        { data: "hari" },
        { data: "proposal_tanggal_mulai" },
        { data: "nama_kegiatan" },
        { data: "nama_kegiatan" },
        { data: "proposal_status" },
        { data: "pj_status" },
      ],
      filterBy: [0, 1],
      rowCallback: function (row, data) {
        $("td:eq(3)", row).html(
          `<div class="ml-auto mr-auto d-flex justify-content-center">
          <button class="btn btn-info" data-action="view" style="cursor:pointer;" data-id="` +
            data.id +
            `">Detail</button>
          </div>`
        );
        if (data.count_pj > 0) {
          if (data.pj_status == 'null' || data.pj_status == '0') {
            $("td:eq(5)", row).html(dropdown2);
          } else if (data.pj_status == '1') {
            $("td:eq(5)", row).html('<span style="font-size:100%" class="badge badge-success">Approved</span');
          } else {
            $("td:eq(5)", row).html('<span style="font-size:100%" class="badge badge-danger">Rejected</span');
          }
        } else {
          $("td:eq(5)", row).html('Belum ada LPJ');
        }

        // if (!data.pj_status) {
        //   $("td:eq(4)", row).html("");
        // } else {
        //   $("td:eq(4)", row).html(
        //     `<div class="ml-auto mr-auto d-flex justify-content-center">
        //       <img src="https://img.icons8.com/material-outlined/24/000000/link--v1.png" data-action="view" data-id="` +
        //       data.id +
        //       `">
        //       </div>`
        //   );
        // }

        if (data.proposal_status == 0) {
          $("td:eq(4)", row).html(
            `<h6 style="color:#FFCC29;">PERSIAPAN APPROVAL</h6>`
          );
        } else if (data.proposal_status == 1) {
          $("td:eq(4)", row).html(
            `<h6 style="color:#FFA450;">REQUEST APPROVAL</h6>`
          );
        } else if (data.proposal_status == 2) {
          $("td:eq(4)", row).html(
            `<h6 style="color:#F04747;">ONGOING KEGIATAN</h6>`
          );
        }
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Proposal & LPJ Kegiatan";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Proposal & LPJ Kegiatan";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/pr/proposal_lpj_kegiatan/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
    });
  },
  components: {
    vSelect,
    datepicker,
    CurrencyInput,
  },
};
</script>
